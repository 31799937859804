import Login from "./pages/LoginContent";
import SuperAdminUser from "./pages/SuperAdminUser";
import Stats from "./pages/Stats";
import Setup from "./pages/Setup";

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import "./App.css";
import React from "react";

function App() {
	const ProtectedRoute = ({ children }) => {
		const isAuthenticated = localStorage.getItem("token");
		if (!isAuthenticated) {
			return <Navigate to="/" replace />;
		}
		return children;
	};
	const SuperAdminRoute = ({ children }) => {
		const role = localStorage.getItem("role");
		if (role !== 'super-admin') {
			return <Navigate to="/admin" replace />;
		}
		return children;
	};
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Login />} />
				<Route
					exact
					path="/super-admin"
					element={
						<ProtectedRoute>
							<SuperAdminRoute>
								<SuperAdminUser />
							</SuperAdminRoute>
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path="/admin"
					element={
						<ProtectedRoute>
							<SuperAdminUser />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path="/final-user"
					element={
						<ProtectedRoute>
							<SuperAdminUser />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path="/stats"
					element={
						<ProtectedRoute>
							<Stats />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path="/group-stats"
					element={
						<ProtectedRoute>
							<Stats />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path="/setup"
					element={
							<Setup />
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
