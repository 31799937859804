import React from "react";
import httpClient from "../../axios";
import Nav from "../../Components/Nav";

const Setup = () => {
    const http = httpClient();
    return (
        <div>
            <title>CFAA Verification Inspection Simulator - Setup Instructions</title>
            <h1>CFAA Verification Inspection Simulator - Instructions</h1>
            <p>Thank you for purchasing the CFAA Verification Inspection Simulator! Please follow the instructions below to get started:</p>
            <ol>
                <li>Navigate to <a href="https://cfaa.vrts.ca/simulation" target="_blank">https://cfaa.vrts.ca/simulation</a></li>
                <li>Once the application fully loads, you'll be prompted to enter your email.</li>
                <li>Type in the same email you used for purchasing the product, and click <b>Proceed</b>.</li>
                <li>Select <b>Request New Password</b>.</li>
                <li>Check your email's inbox (and spam folder if it's not there) for an email containing your verification PIN.</li>
                <li>Enter the PIN into text box in the application and select <b>Request New Password</b> again.</li>
                <li>If the PIN is accepted, you'll be prompted to create your own password.</li>
                <li>Enter your password, and enter it again to confirm it and select <b>Reset Password</b></li>
                <li>That's it! You can now log in any time to resume your progress.</li>
            </ol>
            <p></p>
        </div>
    );
};

export default Setup;